import React, { useEffect, useState, useRef } from 'react';
import {
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  View,
  Platform,
} from 'react-native';
import {
  BarCodePoint,
  BarCodeScanner,
  BarCodeScannerResult,
} from 'expo-barcode-scanner';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import BarcodeMask from 'react-native-barcode-mask';
import { Camera } from 'expo-camera';

import Text from 'components/StyledText';

const ScanQRCodeScreen = ({
  navigation,
}: {
  navigation: StackNavigationHelpers;
}): JSX.Element => {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [type, setType] = useState<'front' | 'back'>('back');
  const isWeb = Platform.OS === 'web';

  const kitId = useRef('');

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const determineBarcodeScan = (event: BarCodeScannerResult) => {
    const { data, bounds: { origin = {} as BarCodePoint } = {} } = event;
    let id = data.split('code=')[1];
    if (!id) {
      id = data.split('kitid=')[1];
    }
    if (kitId.current !== id) {
      kitId.current = id;
      if (isWeb) {
        navigation.navigate('ConfirmTestingRequirementsScreen', {
          kitId: id,
        });
      } else {
        handleBarCodeScanned({ id, origin });
      }
    }
  };

  const handleBarCodeScanned = ({
    id,
    origin,
  }: {
    id: string;
    origin: BarCodePoint;
  }) => {
    const finderWidth = 280;
    const finderHeight = 230;
    const width = Dimensions.get('window').width;
    const height = Dimensions.get('window').height;
    const viewMinX = (width - finderWidth) / 2;
    const viewMinY = (height - finderHeight) / 2;
    const { x, y } = origin;
    if (
      isWeb ||
      (x >= viewMinX &&
        y >= viewMinY &&
        x <= viewMinX + finderWidth / 2 &&
        y <= viewMinY + finderHeight / 2)
    ) {
      navigation.navigate('ConfirmTestingRequirementsScreen', {
        kitId: id,
      });
    }
  };

  if (hasPermission === null) {
    return <Text>Requesting camera permission</Text>;
  }

  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  return (
    <View style={styles.flex}>
      <SafeAreaProvider>
        {isWeb ? (
          <>
            <Camera
              style={StyleSheet.absoluteFillObject}
              onBarCodeScanned={determineBarcodeScan}
              barCodeScannerSettings={{
                barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
              }}
            >
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    setType(
                      type === Camera.Constants.Type.back
                        ? Camera.Constants.Type.front
                        : Camera.Constants.Type.back
                    );
                  }}
                >
                  <Text xl style={[styles.text, styles.instructionText]}>
                    Flip
                  </Text>
                </TouchableOpacity>
              </View>
            </Camera>
          </>
        ) : (
          <>
            <BarCodeScanner
              onBarCodeScanned={determineBarcodeScan}
              style={[StyleSheet.absoluteFillObject, styles.container]}
              type={type}
              barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
            >
              <BarcodeMask edgeColor="#62B1F6" showAnimatedLine={false} />
            </BarCodeScanner>
            <View style={styles.view}>
              <TouchableOpacity
                style={styles.opacity}
                onPress={() => {
                  setType(
                    type === BarCodeScanner.Constants.Type.back
                      ? BarCodeScanner.Constants.Type.front
                      : BarCodeScanner.Constants.Type.back
                  );
                }}
              >
                <Text large style={styles.text}>
                  Flip
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.instructionContainer}>
              <Text xl style={[styles.text, styles.instructionText]}>
                Please scan the QR code printed on your kit
              </Text>
            </View>
          </>
        )}
      </SafeAreaProvider>
    </View>
  );
};

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  opacity: {
    flex: 1,
    marginTop: 50,
    alignItems: 'flex-end',
  },
  text: {
    margin: 25,
    color: 'white',
  },
  textWeb: {
    fontSize: 25,
    margin: 25,
    color: 'white',
  },
  instructionContainer: {
    justifyContent: 'space-between',
    flex: 1,
    marginTop: 200,
  },
  instructionText: {
    marginTop: 100,
    textAlign: 'center',
  },
  view: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  buttonContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    margin: 20,
  },
  button: {
    flex: 0.1,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
});

export default ScanQRCodeScreen;
